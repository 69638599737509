<template>
  <section>
    <div class="places">
      <div class="filter">
        <ul>
          <li v-for="(item, index) in cat" :key="index" :class="{ active: index == isCat }" @click="isCat = index">
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>

      <div class="load" v-if="isLoad">
        <Loader />
      </div>

      <div class="list" v-else>
        <router-link :to="'/space?id=' + item.id" class="list__item" v-for="(item, index) in filtered" :key="index">
          <div class="list__item-image">
            <img :src="`${item.photo_main}?s=${timestamp}`" :alt="'item_' + index" />
          </div>
          <div class="list__item-content">
            <p class="name">{{ item.name }}</p>
            <p class="adress">
              <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512">
                <path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
                <path
                  d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
                />
              </svg>
              {{ item.adress }}
            </p>
            <p class="category">{{ item.category }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from "axios";
  import Loader from "../components/global/Loader";
  import { ref, onBeforeMount, watch } from "vue";
  export default {
    components: { Loader },

    setup() {
      const isLoad = ref(true);
      const isCat = ref(0);
      const data = ref([]);
      const filtered = ref([]);
      const cat = ref([]);
      let timestamp = Date.now().toLocaleString();

      const fetchData = async () => {
        try {
          let url =
            "https://sheets.googleapis.com/v4/spreadsheets/1K6j5VzAHJ-GPfuR-lhs4-8mXcpvNzkYhvw0Q-gEu97U/values/Spaces?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas";
          let get = await axios.get(url),
            header = get.data.values[0],
            rows = get.data.values.slice(1);

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            data.value.push(formattedRow);
          }

          filtered.value = data.value;

          cat.value = ["всі", ...new Set(data.value.map((e) => e.category))];

          setTimeout(() => {
            isLoad.value = false;
          }, 500);
        } catch (e) {
          console.log(e);
        }
      };

      onBeforeMount(() => fetchData());

      watch(
        () => isCat.value,
        () => {
          isCat.value == 0
            ? (filtered.value = data.value)
            : (filtered.value = data.value.filter((e) => {
                return e.category.includes(cat.value[isCat.value]) || e.subcategory.includes(cat.value[isCat.value]);
              }));
        }
      );

      return { isLoad, isCat, data, filtered, cat, timestamp };
    },
  };
</script>
<style lang="scss" scoped>
  .places {
    .filter {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          padding: 0 0.6em;
          margin: 0.2em;
          display: flex;
          align-items: center;
          cursor: pointer;
          text-transform: lowercase;

          &.active,
          &:hover {
            background: $green;
            border-radius: 999px;
            color: #fff;

            &::before {
              background: #fff;
            }
          }
          &::before {
            position: relative;
            content: "";
            width: 8px;
            height: 8px;
            background: $green;
            border-radius: 4px;
            margin: 4px 2px 0 6px;
          }
        }
      }
    }

    .load {
      display: grid;
      place-items: center;
      min-height: 520px;
    }

    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-gap: 1em;
      margin-top: 2em;

      .list__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
        color: #333;
        background: #fff;
        cursor: pointer;

        &:hover {
          .list__item-image {
            filter: brightness(0.5);
          }
        }

        .list__item-image {
          position: relative;
          width: 100%;
          height: 200px;
          transition: 0.2s ease;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            object-position: center;
          }
        }

        .list__item-content {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: 1em;

          p.name {
            font-weight: bold;
            text-transform: uppercase;
          }

          p.adress {
            font-size: 0.8em;

            svg {
              width: 12px;
              height: 12px;
              margin-right: 6px;
            }
          }

          p.category {
            position: relative;
            display: flex;
            align-items: center;
            font-weight: bold;
            color: $mid-grey;

            &::before {
              content: "";
              width: 8px;
              height: 8px;
              border-radius: 4px;
              margin: 2px 8px 0 0;
              background: $green;
            }
          }
        }
      }
    }
  }
</style>
